import zanrooConstants from '@zanroo/constants'

const { permissions } = zanrooConstants
export { permissions }
export const PERMISSION_DENY_MESSAGE = 'You Do Not Have Permission To Access'

export const FEATURES =
  [
    {
      key: 'canned_response',
      label: 'Canned Response'
    },
    {
      key: 'auto_response',
      label: 'Auto Response'
    },
    {
      key: 'post_blocker',
      label: 'Ticket Automation'
    },
    {
      key: 'auto_assignment',
      label: 'Auto Assignment'
    },
    {
      key: 'verification_link',
      label: 'Verification Link'
    },
    {
      key: 'ticket_timer',
      label: 'Ticket Timer'
    },
    {
      key: 'cat_tag',
      label: 'Category & Tag'
    },
    {
      key: 'survey',
      label: 'Survey'
    },
    {
      key: 'agile',
      label: 'Agile Board'
    },
    {
      key: 'sms_otp',
      label: 'SMS OTP'
    },
    {
      key: 'ticket_viewer',
      label: 'Show Viewer'
    },
    {
      key: 'quality_check',
      label: 'Quality Check'
    },
    {
      key: 'customer_tag',
      label: 'Customer Tag'
    },
    {
      key: 'team_dashboard',
      label: 'Team Dashboard'
    },
    {
      key: 'fb_auto_mention',
      label: 'FB Auto Mention'
    },
    {
      key: 'video_call',
      label: 'Video Call'
    },
    {
      key: 'mobile_notification',
      label: 'Mobile Notification'
    },
    {
      key: 'remove_media',
      label: 'Remove Media'
    },
    {
      key: 'custom_form',
      label: 'Submit Custom Form'
    },
    {
      key: 'chatbot_dashboard',
      label: 'Chatbot Dashboard'
    }
  ]

export const ZANROO_APP = {
  app_name: 'Zanroo',
  app_id: process.env.REACT_APP_FACEBOOK_APP_ID,
  app_secret: process.env.REACT_APP_FACEBOOK_APP_SECRET_ENCRYPTED,
  app_note: 'Default'
}

export const TWITTER_ZANROO_APP = {
  app_name: 'Zanroo',
  app_id: process.env.REACT_APP_TWITTER_APP_ID,
  client_id: process.env.REACT_APP_TWITTER_CLIENT_ID,
  app_secret: process.env.REACT_APP_TWITTER_CLIENT_SECRET_ENCRYPTED,
  app_note: 'Default'
}
